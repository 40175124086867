/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Typography, Dropdown, Tooltip, Checkbox, ButtonIcon } from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import moment from 'moment';
import W39F3002TextEdit from './W39F3002TextEdit';
import W39F3015 from '../../W39F3015/W39F3015';
import DateBoxPicker from '../../../../common/form-material/date-box';
import Api from '../../../../../services/api';
import History from '../../../../libs/history';
import {Column} from "devextreme-react/data-grid";
import {Combo, MForm} from "../../../../common/form-material";
W39F3002Tab2.propTypes = {
    classes: PropTypes.object,
    goalProps: PropTypes.object,
    updateProportion: PropTypes.func,
    mode: PropTypes.number,
};

W39F3002Tab2.defaultProps = {
    classes: {},
    goalProps: { GoalID: '', Type: '' },
    updateProportion: null,
    mode: 1,
};

function W39F3002Tab2(props) {
    const nameColumnCboConcurrent = [
        {
            "FieldName": "DivisionName",
            "Caption": Config.lang("Don_vi"),
        },
        {
            "FieldName": "DepartmentName",
            "Caption": Config.lang("Phong_ban"),
        },
        {
            "FieldName": "TeamName",
            "Caption": Config.lang("To_nhom"),
        },
        {
            "FieldName": "DutyName",
            "Caption": Config.lang("Chuc_vu"),
        },
        {
            "FieldName": "WorkName",
            "Caption": Config.lang("Cong_viec"),
        }
    ];

    const { classes, goalProps, updateProportion, mode } = props;

    const { GoalID, Type } = goalProps;

    const [data, setData] = useState({});
    const [dataCboComputeMethod, setDataCboComputeMethod] = useState([]);
    const [dataCboGroupTarget, setDataCboGroupTarget] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpenW39F3015Pop, setIsOpenW39F3015Pop] = useState(false);
    const [objectRefs, setObjectRefs] = useState([]);
    const [dataCboConcurrent, setDataCboConcurrent] = useState([]);
    const [isCheckEmployeeConcurrent, setIsCheckEmployeeConcurrent] = useState(false);
    const [error, setError] = useState({});
    const [isConcurrent, setIsConcurrent] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const proportionRef = useRef(null);
    const groupTargetRef = useRef(null);
    const originData = useRef(null);
    const StrObjectRefs = useRef([]);
    const columnCBo = useRef(nameColumnCboConcurrent);
    const isSetConcurrentID = useRef(false);
    const dataOld = useRef({});
    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            FormID: 'W39F3002',
            Language: Config.language ?? '84',
            GoalID: GoalID,
            Mode: 2,
            Type: mode,
        };
        dispatch(
            W39F3002Actions.getData(param, (err, dt) => {
                if (err) return Config.popup.show('ERROR', err);
                if (dt && dt.length > 0) {
                    originData.current = dt[0];
                    setData(dt[0]);
                    setIsConcurrent(!!dt[0]?.IsConcurrent);
                    dataOld.current = {...dataOld.current,...dt[0],
                        GoalName: `${dt[0]?.GoalID || ""} - ${dt[0]?.GoalName || ""}`,
                        IsConcurrent : dt[0]?.IsConcurrent ?
                            Config.lang("Co") :
                            Config.lang("Khong"),
                    };
                    loadCboGroupTarget(dt[0]);
                    loadCboConcurrent(dt[0]);
                    loadCheckEmployeeConcurrent(dt[0])
                }
            })
        );
    };


    const loadCboConcurrent = (dt) => {
        const params = {
            FormID: "W39F3002",
            Language: Config.language || 84,
            EmployeeID: dt?.EmployeeID ?? "",
            OrgChartID: "",
            GoalPlanID: dt?.GoalPlanID ?? "",
            GoalMode: dt?.GoalMode ?? "",
            TransID: "",
            Mode: 2,
        };
        dispatch(
            W39F3002Actions.getCboConcurrent(params,(error, data) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
                setDataCboConcurrent(data || []);
            })
        )
    };

    const loadCheckEmployeeConcurrent = (dt) => {
        const param = {
            EmployeeID: dt?.EmployeeID || ""
        };
        dispatch(
            W39F3002Actions.getCheckEmployeeConcurrent(param, (err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                setIsCheckEmployeeConcurrent(!!data?.Status)
            })
        );
    };

    const onUpdate = () => {
        const concurrentID = isConcurrent && data?.Type === 'Object' ?
            (data?.ConcurrentID?.ConcurrentID || "") : "";
        const voucherID = isConcurrent && data?.Type === 'Object' ?
            (data?.ConcurrentID?.VoucherID || "") : "";
        const validateRules = [];
        if(isConcurrent) {
           validateRules.push({
               name: 'ConcurrentID',
               rules: ['isRequired'],
               value: data?.ConcurrentID?.ConcurrentID ?? '',
           });
        }
        const validateForm = MForm.formValidation(validateRules);

        if (Object.keys(validateForm).length > 0) {
            setError(validateForm);
            return;
        }
        const params = {
            FormID: "W39F3002",
            GoalID: data?.GoalID || "",
            TransID: data?.TransID || "",
            IsConcurrent: +isConcurrent ?? 0,
            ConcurrentID: concurrentID,
            VoucherID: voucherID,
        };
        dispatch(
            W39F3002Actions.update(params,async (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                } else {
                    isSetConcurrentID.current = false;
                    setIsChanged(false);
                    await saveHistory2(params);
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    loadData();
                }

            })
        )
    };

    const loadCboComputeMethod = () => {
        const param = {
            Language: Config.language ?? '84',
        };
        dispatch(
            W39F3002Actions.getComboComputeMethod(param, (err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setDataCboComputeMethod(data);
                }
            })
        );
    };

    const loadCboGroupTarget = async (dt) => {
        const param = {
            GoalPlanID: dt?.GoalPlanID ?? '',
        };

        const res = await Api.put('/w39f3001/load-group-target', param);

        const dtGT = res?.data ?? [];
        setDataCboGroupTarget(dtGT);
    };

    const loadObjectRefs = () => {
        dispatch(
            W39F3002Actions.getObjectRef((err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setObjectRefs(data);
                }
            })
        );
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([loadData(), loadCboComputeMethod(), loadObjectRefs()]).then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const concurrent = data?.ConcurrentID || data?.ConcurrentID?.ConcurrentID;
        if(!isSetConcurrentID.current && concurrent !== "" && dataCboConcurrent?.length > 0) {
            isSetConcurrentID.current = true;
            const concurrentID = dataCboConcurrent.find(item => item.ConcurrentID === concurrent) || "";
            const dutyName = (concurrentID?.DepartmentName || concurrentID?.DutyName) ?
                `${concurrentID?.DepartmentName || ""} - ${concurrentID?.DutyName || ""}` : null;
            dataOld.current = {...dataOld.current,DutyName: dutyName};
            setData({ ...data, ConcurrentID : concurrentID || ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.ConcurrentID, dataCboConcurrent]);

    const handleOpenPopup = (name, flag = false) => {
        switch (name) {
            case 'W39F3015':
                if (flag && !data?.IsEditMethod) return;
                setIsOpenW39F3015Pop(flag);
                break;
            default:
                break;
        }
    };

    const handleChangeValue = (key, value, ref) => {
        switch (key) {
            case 'Proportion':
                if (value[0] && value[0] === ',') {
                    value = '0' + value;
                }
                value = Number(value.replace(',', '.'));
                setData({ ...data, [key]: value });
                checkProportion(value);
                break;
            case 'ValidDateTo':
            case 'GroupGoalID':
                setData({ ...data, [key]: value });
                updateData(key, value);
                break;
            case 'StrObjectiveRef':
                setData({ ...data, [ref?.RefID]: value });
                updateData(key, value, ref);
                break;
            case 'ConcurrentID':
                if (!value) {
                    setError({ ...error, [key]: Config.lang('Truong_nay_bat_buoc_nhap') });
                } else {
                    setError({ ...error, [key]: '' });
                }
                setData({ ...data, [key]: value});
                break;
            default:
                break;
        }
    };

    const checkProportion = (value) => {
        const params = {
            FormID: 'W39F3002',
            GoalPlanID: data?.GoalPlanID ?? '',
            GoalID: data?.GoalID ?? '',
            Proportion: value ?? '',
        };

        dispatch(W39F3002Actions.checkProportion(params, (err, dt) => {
            if (err) return Config.popup.show('ERROR', err);
            if (dt?.Status === 0) {
                updateData('Proportion', value);
            } else if (dt?.Status === 1 && dt?.MsgAsk === 0 ) {
                if (proportionRef.current) proportionRef.current.revert();
                setData({ ...data, Proportion: originData.current?.Proportion ?? '' });
                return Config.popup.show('ERROR', dt?.Message, () => {});
            }
        }));
    };

    const updateData = (key, value, ref) => {
        let param = {
            GoalID: data?.GoalID ?? '',
        };

        switch (key) {
            case 'Proportion':
            case 'ValidDateTo':
            case 'GroupGoalID':
                param = {
                    ...param,
                    [key]: value ?? '',
                };
                setLoading(true);
                dispatch(
                    W39F3002Actions.updateDetailInfo(param, (err, dt) => {
                        setLoading(false);
                        if (err) {
                            switch (key) {
                                case 'Proportion':
                                    if (proportionRef.current) proportionRef.current.revert();
                                    setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    break;
                                case 'ValidDateTo':
                                        setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    break;
                                case 'GroupGoalID':
                                    if (groupTargetRef.current) groupTargetRef.current.setPreviousValue();
                                        setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    break;
                                default:
                                    break;
                            }
                            const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                            return Config.popup.show('ERROR', msg);
                        }
                        switch (key) {
                            case 'Proportion':
                                if (dt?.Status) {
                                    if (proportionRef.current) proportionRef.current.revert();
                                    setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    Config.notify.show('error', Config.lang('Cap_nhat_trong_so_that_bai'), 2000);
                                } else {
                                    saveHistory(key, value);
                                    originData.current = data;
                                    updateProportion();
                                    Config.notify.show('success', Config.lang('Cap_nhat_trong_so_thanh_cong'), 2000);
                                }
                                break;
                            case 'ValidDateTo':
                                if (dt?.Status) {
                                    setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    Config.notify.show('error', Config.lang('Cap_nhat_ngay_hoan_thanh_that_bai'), 2000);
                                } else {
                                    originData.current = data;
                                    Config.notify.show('success', Config.lang('Cap_nhat_ngay_hoan_thanh_thanh_cong'), 2000);
                                }
                                break;
                            case 'GroupGoalID':
                                if (dt?.Status) {
                                    if (groupTargetRef.current) groupTargetRef.current.setPreviousValue();
                                    setData({ ...data, [key]: originData.current?.[key] ?? '' });
                                    Config.notify.show('error', Config.lang('Cap_nhat_nhom_that_bai'), 2000);
                                } else {
                                    originData.current = data;
                                    Config.notify.show('success', Config.lang('Cap_nhat_nhom_thanh_cong'), 2000);
                                }
                                break;
                            default:
                                break;
                        }
                    })
                );
                break;
            case 'StrObjectiveRef':
                objectRefs.forEach((i) => {
                    if (i?.IsUsed) {
                        param[i?.RefID ?? 'strObjectRef'] = i?.RefID === ref?.RefID ? value : data?.[i?.RefID ?? 'strObjectRef'] ?? '';
                    }
                });
                setLoading(true);
                dispatch(
                    W39F3002Actions.updateObjectRef(param, (err, dt) => {
                        setLoading(false);
                        if (err) {
                            const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                            return Config.popup.show('ERROR', msg);
                        }

                        if (dt?.Status) {
                            const strObjectRef = StrObjectRefs.current?.[ref?.RefID ?? 'strObjectRef'] ?? null;
                            if (strObjectRef) strObjectRef.revert();
                            setData({ ...data, [ref?.RefID ?? 'strObjectRef']: originData.current?.[ref?.RefID ?? 'strObjectRef'] ?? '' });
                            Config.notify.show('error', Config.lang(`Cap_nhat_gia_tri_%${ref?.Name ?? ''}%_that_bai`), 2000);
                        } else {
                            saveHistory(key, value, ref);
                            originData.current = data;
                            Config.notify.show('success', Config.lang(`Cap_nhat_gia_tri_%${ref?.Name ?? ''}%_thanh_cong`), 2000);
                        }
                    })
                );
                break;
            default:
                break;
        }
    };

    const saveHistory = async (key, value, ref) => {
        if (!data) return false;
        if (data?.GoalID) {
            let _data = {
                GoalObjectName: `${data?.GoalID ?? ''}-${data?.GoalName ?? ''}`,
            };

            let _dataOld = {
                GoalObjectName: `${data?.GoalID ?? ''}-${data?.GoalName ?? ''}`,
            };

            let captions = {
                GoalObjectName: 'Ten_muc_tieu',
            };

            switch (key) {
                case 'Proportion':
                    _data.Proportion = value ?? 0;
                    _dataOld.Proportion = originData.current?.Proportion ?? 0;
                    captions.Proportion = 'Trong_so';
                    break;
                case 'StrObjectiveRef':
                    objectRefs.forEach((i) => {
                        if (i?.IsUsed) {
                            _data[i?.RefID ?? 'strObjectRef'] = i?.RefID === ref?.RefID ? value : data?.[i?.RefID ?? 'strObjectRef'] ?? '';
                            _dataOld[i?.RefID ?? 'strObjectRef'] = originData.current?.[i?.RefID ?? 'strObjectRef'] ?? '';
                            captions[i?.RefID ?? 'strObjectRef'] = i?.Name ?? '';
                        }
                    });
                    break;
                default:
                    break;
            }

            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: 'D39',
                TransID: data?.GoalID ?? '',
                TransactionID: 'W39F3002',
                TransactionName: 'Thông tin mục tiêu',
            };

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    const saveHistory2 = async (dt) => {
        if (!dt) return false;
        const GoalID = dt?.GoalID || "";
        const dutyName =  (data?.ConcurrentID?.DepartmentName || data?.ConcurrentID?.DutyName) ?
            `${data?.ConcurrentID?.DepartmentName || ""} - ${data?.ConcurrentID?.DutyName || ""}` : null;
        if (GoalID) {
            // data Content
            const _data = {
                GoalName: dt?.GoalID + " - " + data?.GoalName,
                IsConcurrent: isConcurrent ? Config.lang("Co") : Config.lang("Khong"),
                DutyName: dutyName,
            };

            //name Content
            const captions = {
                GoalName: "Ten_muc_tieu",
                IsConcurrent: "Kiem_nhiem",
                DutyName: "Chuc_vu_kiem_nhiem",
            };

            const _dataOld = dataOld.current;

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: 1,
                ModuleID: "D39",
                TransID: GoalID,
                TransactionID: "W39F3002",
                TransactionName: "Thông tin mục tiêu",
            };
            const history = new History(options); //Init history
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const getComputeMethodName = () => {
        const computeMethod = data?.ComputeMethod ?? '';
        const computeMethodObj = dataCboComputeMethod.find((i) => i.ComputeMethod === computeMethod);
        return computeMethodObj?.ComputeMethodName ?? '';
    };

    const renderStrObjectRef = (i, idx) => {
        return i?.IsUsed ? (
            <div key={i?.RefID ?? idx} className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                <Typography type={'p2'}>{i?.Name ?? ''}</Typography>
                <W39F3002TextEdit
                    ref={(ref) => (StrObjectRefs.current[i?.RefID ?? 'strObjectRef'] = ref)}
                    classes={classes}
                    value={data?.[i?.RefID ?? 'strObjectRef'] ?? ''}
                    disabled={!data.IsProportionEdit}
                    className={`${classes.divTextEditValue} ${classes.maxWidth350} ${classes.coreFont} ${classes.pd0} ${classes.pdr25} ${
                        !data.IsProportionEdit ? classes.disabled : ''
                    }`}
                    onSave={(e) => handleChangeValue('StrObjectiveRef', e, i)}
                />
            </div>
        ) : null;
    };

    const isDisabled = goalProps?.Type === "Object" && data?.IsAllEdit === 0 && data?.IsConcurrent === 1;
    return (
        <div id={'W39F3002Tab2'} className={classes.container}>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: '#W39F3002Tab2' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            {data && Object.keys(data).length > 0 && (
                <div>
                    <div className={`${classes.flexRow} ${classes.gap10}`}>
                        <Icon id={'Info'} name={'Info'} type={'filled'} viewBox />
                        <div className={`${classes.flexColumn} ${classes.gap20} ${classes.fullWidth}`} style={{ marginTop: 7 }}>
                            <Typography type={'h3'}>{Config.lang('Chi_tiet')}</Typography>
                            <Typography type={'h3'}>
                                {Type === 'Object' ? Config.lang('Thong_tin_muc_tieu') : Config.lang('Ket_qua_then_chot')}
                            </Typography>
                            <div className={`${classes.flexRow} ${classes.gap100} ${classes.flexWrap}`}>
                                <div className={`${classes.flexColumn} ${classes.divDetailInfo} ${classes.gap20}`}>
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Ngay_hoan_thanh')}</Typography>
                                        <DateBoxPicker
                                            disabled={!data?.IsAllEdit}
                                            onValueChanged={(e) => handleChangeValue('ValidDateTo', e.value)}
                                            placeholder={'dd/mm/yyyy'}
                                            value={data?.ValidDateTo ?? ''}
                                            width={170}
                                            dateBoxProps={{
                                                className: classes.dBP,
                                                inputAttr: {
                                                    class: `${classes.inputDBP} ${classes.coreFont} ${!data?.IsAllEdit ? classes.linkDisabled2 : ''}`,
                                                },
                                            }}
                                            styleForm={{
                                                margin: 0,
                                            }}
                                        />
                                    </div>
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Trong_so')}</Typography>
                                        <W39F3002TextEdit
                                            ref={proportionRef}
                                            classes={classes}
                                            value={`${data?.Proportion ?? ''}`}
                                            disabled={!data.IsProportionEdit}
                                            className={`${classes.divTextEditValue} ${classes.maxWidth350} ${classes.coreFont} ${classes.pd0} ${
                                                classes.pdr25
                                            } ${!data.IsProportionEdit ? classes.disabled : ''}`}
                                            onSave={(e) => handleChangeValue('Proportion', e)}
                                            type={'number'}
                                        />
                                    </div>
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Phuong_phap_do')}</Typography>
                                        <Typography
                                            type={'h3'}
                                            color={'info'}
                                            className={`${classes.spanLink} ${data?.IsEditMethod ? classes.pointerCursor : classes.linkDisabled2}`}
                                            onClick={() => handleOpenPopup('W39F3015', true)}
                                            fullWidth={false}
                                        >
                                            {getComputeMethodName()}
                                        </Typography>
                                    </div>
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Nguoi_tao')}</Typography>
                                        <Typography type={'h3'}>{data?.CreateUserName ?? ''}</Typography>
                                    </div>
                                    {objectRefs.map((i, idx) => renderStrObjectRef(i, idx))}
                                </div>
                                <div className={`${classes.flexColumn} ${classes.divDetailInfo} ${classes.gap20}`}>
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Bang_muc_tieu')}</Typography>
                                        <Typography type={'h3'}>{data?.GoalPlanName ?? ''}</Typography>
                                    </div>
                                    {Type !== 'Key' && (
                                        <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                            <Typography type={'p2'}>{Config.lang('Nhom')}</Typography>
                                            <Dropdown
                                                ref={(ref) => (groupTargetRef.current = ref)}
                                                dataSource={dataCboGroupTarget}
                                                displayExpr={'GroupGoalName'}
                                                noDataText={Config.lang('Khong_co_du_lieu')}
                                                onChange={(e) => handleChangeValue('GroupGoalID', e ? e.value : '')}
                                                valueExpr={'GroupGoalID'}
                                                value={data?.GroupGoalID ?? 0}
                                                disabled={!data?.IsAllEdit}
                                                viewType="outlined"
                                                className={classes.ddGroupTarget}
                                            />
                                        </div>
                                    )}
                                    {/* {Type !== 'Key' && <div className={`${classes.divDetailInfoChild} ${classes.gap10}`}>
                                    <Typography type={'p2'}>{Config.lang('Chia_se')}</Typography>
                                    <Typography type={'p2'} color={'info'}>{data?.ShareType ?? ''}</Typography>
                                </div>} */}
                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                        <Typography type={'p2'}>{Config.lang('Ngay_tao')}</Typography>
                                        <Typography type={'h3'}>{data?.CreateDate ? moment(data?.CreateDate).format('DD/MM/YYYY') : ''}</Typography>
                                    </div>
                                    {isCheckEmployeeConcurrent && goalProps?.Type === "Object" ?
                                        <>
                                            <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                                <Checkbox
                                                    label={Config.lang("Tao_muc_tieu_cho_chuc_danh_kiem_nhiem")}
                                                    checked={isConcurrent}
                                                    disabled={goalProps?.Type === "Object" && !data?.IsAllEdit}
                                                    onChange={e => {
                                                        setIsConcurrent(e.target.checked);
                                                        setIsChanged(true);
                                                    }}
                                                />
                                            </div>
                                            {isConcurrent ?
                                                <>
                                                    <div className={`${classes.divDetailInfoChild} ${classes.gap10} ${classes.alignCenter}`}>
                                                        <div className={"display_row align-center"}>
                                                            <label className={"mgr15"}>
                                                                {Config.lang("Thong_tin_kiem_nhiem")}
                                                            </label>
                                                            <Tooltip
                                                                title={isDisabled ?
                                                                    ""
                                                                    :  Config.lang("Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen")
                                                                }
                                                            >
                                                                <Icon disabled={isDisabled} name={'info'}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div  className={` display_row align-center ${classes.gap10} ${classes.alignCenter}`}>
                                                        <Combo.LoadMore
                                                            error={error && error['ConcurrentID']}
                                                            dataSource={dataCboConcurrent}
                                                            totalItems={dataCboConcurrent.length}
                                                            keyExpr={"ConcurrentID"}
                                                            valueExpr={"ConcurrentID"}
                                                            loading={loading}
                                                            acceptCustomValue={false}
                                                            value={data.ConcurrentID}
                                                            style={{width: "80%"}}
                                                            required={true}
                                                            displayExpr={(data) => {
                                                                if(!data) return null;
                                                                return data?.DepartmentName + " - " + data?.DutyName;
                                                            }}
                                                            disabled={isDisabled}
                                                            showColumnHeaders={true}
                                                            stylingMode={'underlined'}
                                                            showClearButton={true}
                                                            onValueChanged={e => handleChangeValue("ConcurrentID", e ? e.value : '')}
                                                        >
                                                            {columnCBo?.current?.map((field, idxField) => {
                                                                return (
                                                                    <Column key={idxField}
                                                                            dataField={field.FieldName}
                                                                            caption={field.Caption}
                                                                    />
                                                                )
                                                            })}
                                                        </Combo.LoadMore>
                                                    </div>
                                                </>
                                                : ""}
                                            {isChanged ?
                                                    <div className={"display_row"}>
                                                        <ButtonIcon
                                                            size={"small"}
                                                            viewType={"outlined"}
                                                            name={"Approval"}
                                                            className={"mgr20"}
                                                            onClick={onUpdate}
                                                        />
                                                        <ButtonIcon
                                                            size={"small"}
                                                            viewType={'outlined'}
                                                            name={"Close"}
                                                        />
                                                    </div>
                                                :""
                                            }
                                        </>
                                        :""}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpenW39F3015Pop && (
                <W39F3015
                    open={isOpenW39F3015Pop}
                    handleOpenPopup={handleOpenPopup}
                    dataCboComputeMethod={dataCboComputeMethod}
                    goalProps={goalProps}
                />
            )}
        </div>
    );
}

export default W39F3002Tab2;
