/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 31/3/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Button, Checkbox, Popover, Typography } from 'diginet-core-ui/components';
import { CalendarNew } from 'diginet-core-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import Config from '../../../../config';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W39F3005Actions from '../../../../redux/W3X/W39F3005/W39F3005_actions';
import Api from '../../../../services/api';
import { Combo } from '../../../common/form-material';
import MForm from '../../../common/form-material/form';
import Modal from '../../../common/modal/modal';
import History from '../../../libs/history';
import { useStyle } from './style';
import W39F3005DateModal from './utils/W39F3005DateModal';

W39F3005.propTypes = {
    employee: PropTypes.object,
    open: PropTypes.bool,
    handleOpenW39F3005: PropTypes.func,
};

W39F3005.defaultProps = {
    employee: {},
    open: false,
    handleOpenW39F3005: null,
};

function W39F3005(props) {
    const classes = useStyle();

    const { employee, open, handleOpenW39F3005 } = props;

    const [loading, setLoading] = useState(false);
    const [loadingCboEmployee, setLoadingCboEmployee] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState([]);
    const [dataCboGoalTable, setDataCboGoalTable] = useState([]);
    const [isOpenPopPeriod, setIsOpenPopPeriod] = useState(false);
    const [periodID, setPeriodID] = useState(null);
    const [periodName, setPeriodName] = useState('');
    const [goalPlanID, setGoalPlanID] = useState('');
    const [employeeID, setEmployeeID] = useState(() => {
        return employee.EmployeeID || '';
    });
    const [status1, setStatus1] = useState(1);
    const [status2, setStatus2] = useState(0);
    const [error, setError] = useState({});

    const divPeriod = useRef(null);

    const dataPopup = useRef({
        DateFrom: null,
        DateTo: null,
    });

    const dispatch = useDispatch();

    const paramCboEmployee = {
        skip: 0,
        limit: 10,
        search: employee?.EmployeeID ?? '',
    };

    const periodArr = [
        {
            PeriodID: 0,
            PeriodName: Config.lang('Thang'),
        },
        {
            PeriodID: 1,
            PeriodName: Config.lang('Quy'),
        },
        {
            PeriodID: 2,
            PeriodName: Config.lang('Nam'),
        },
    ];

    const loadCboEmployees = async (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3000',
            Language: Config.language || '84',
            ...paramCboEmployee,
        };
        setLoadingCboEmployee(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployee(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    const rows = _.get(data, 'rows', false) || data;
                    const total = _.get(data, 'total', 0) || _.size(data);
                    setDataCboEmployees({ rows: isReset ? rows : _.concat(dataCboEmployees.rows, rows), total });
                }
            })
        );
    };

    const loadCboGoalTable = async () => {
        const params = {
            FormID: 'W39F3005',
            Type: 'Goal',
            Language: Config?.language ?? '84',
            SearchValue: '',
        };
        const res = await Api.put('/api/w39f1207/load-form', params);
        const msg = res?.message ?? null;
        if (msg) {
            Config.popup2.show('ERROR', msg);
        } else {
            const data = res?.data ?? [];
            setDataCboGoalTable(data);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (employee.EmployeeID) {
            loadCboEmployees(true);
        } else {
            loadCboEmployees();
        }
        loadCboGoalTable();
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setStateErrorText = (objValue) => {
        setError({
            ...(error ? error : {}),
            ...objValue,
        });
        return Object.keys(objValue).length !== 0;
    };

    const handleChange = (key, value) => {
        let validateRules = null;
        let valid = null;
        switch (key) {
            case 'GoalPlanID':
                if (value) {
                    setGoalPlanID(value);

                    const param = {
                        DivisionID: Config.getDivisionID(),
                        Language: Config.language || '84',
                        TranMonth: Config.getHRTransMonth(),
                        TranYear: Config.getHRTransYear(),
                        UserID: Config.profile.UserID,
                        HostID: '',
                        FormID: 'W39F3005',
                        GoalPlanID: value,
                        EmployeeID: employeeID || '',
                    };

                    setLoading(true);

                    dispatch(
                        W39F3005Actions.checkTable(param, (err, data) => {
                            setLoading(false);
                            if (err) return Config.popup2.show('ERROR', err);
                            if (data && data.Status === 1) {
                                setGoalPlanID('');
                                const message = data.Message || Config.lang('Loi_khong_xac_dinh');
                                return Config.popup2.show('ERROR', message);
                            }
                        })
                    );
                } else {
                    setGoalPlanID('');
                }

                validateRules = [
                    {
                        name: [key],
                        rules: ['isRequired'],
                        value: goalPlanID,
                    },
                ];
                valid = MForm.formValidation(validateRules);
                if (Object.keys(valid).length > 0) {
                    setStateErrorText(valid);
                } else {
                    const newError = { ...error };
                    delete newError[key];
                    setError(newError);
                }
                break;
            case 'EmployeeID':
                setEmployeeID(value ? value.EmployeeID : '');

                if (!value) {
                    setGoalPlanID('');
                    paramCboEmployee.search = '';
                    paramCboEmployee.skip = 0;
                    loadCboEmployees(true);
                }

                validateRules = [
                    {
                        name: [key],
                        rules: ['isRequired'],
                        value: value ? value.EmployeeID : '',
                    },
                ];
                valid = MForm.formValidation(validateRules);
                if (Object.keys(valid).length > 0) {
                    setStateErrorText(valid);
                } else {
                    const newError = { ...error };
                    delete newError[key];
                    setError(newError);
                }
                break;
            case 'Status1':
                const newStatus1 = status1 ? 0 : 1;
                setStatus1(newStatus1);
                if (!newStatus1 && !status2) {
                    const newError = { ...error };
                    newError['Status'] = Config.lang('Phai_chon_it_nhat_1_trang_thai');
                    setError(newError);
                } else {
                    const newError = { ...error };
                    delete newError['Status'];
                    setError(newError);
                }
                break;
            case 'Status2':
                const newStatus2 = status2 ? 0 : 1;
                setStatus2(newStatus2);
                if (!status1 && !newStatus2) {
                    const newError = { ...error };
                    newError['Status'] = Config.lang('Phai_chon_it_nhat_1_trang_thai');
                    setError(newError);
                } else {
                    const newError = { ...error };
                    delete newError['Status'];
                    setError(newError);
                }
                break;
            case 'PeriodID':
                togglePopPeriod(false);
                setPeriodID(value);
                break;
            default:
                dataPopup.current[key] = value;
                break;
        }
    };

    const togglePopPeriod = (flag = false) => {
        if (flag) {
            const validateRules = [
                {
                    name: 'EmployeeID',
                    rules: ['isRequired'],
                    value: employeeID,
                },
                {
                    name: 'GoalPlanID',
                    rules: ['isRequired'],
                    value: goalPlanID,
                },
            ];
            const valid = MForm.formValidation(validateRules);
            if (Object.keys(valid).length > 0) {
                return setStateErrorText(valid);
            }
        }
        setIsOpenPopPeriod(flag);
    };

    const handleSelectDate = (data) => {
        setPeriodID(null);

        if (data.PeriodName && data.DateFrom && data.DateTo) {
            setPeriodName(data.PeriodName);
            dataPopup.current['PeriodName'] = data.PeriodName;
            dataPopup.current['DateFrom'] = data.DateFrom;
            dataPopup.current['DateTo'] = data.DateTo;

            const param = {
                DivisionID: Config.getDivisionID(),
                Language: Config.language || '84',
                TranMonth: Config.getHRTransMonth(),
                TranYear: Config.getHRTransYear(),
                UserID: Config.profile.UserID,
                HostID: '',
                FormID: 'W39F3005',
                GoalPlanID: goalPlanID,
                EmployeeID: employeeID || '',
                DateFrom: moment(dataPopup.current.DateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
                DateTo: moment(dataPopup.current.DateTo, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
            };

            setLoading(true);

            dispatch(
                W39F3005Actions.checkExist(param, (err, data) => {
                    setLoading(false);
                    if (err) return Config.popup2.show('ERROR', err);
                    if (data && data.Status === 1) {
                        setPeriodName('');
                        dataPopup.current['PeriodName'] = '';
                        dataPopup.current['DateFrom'] = null;
                        dataPopup.current['DateTo'] = null;
                        const message = data.Message || Config.lang('Loi_khong_xac_dinh');
                        Config.popup2.show('ERROR', message);
                    }
                    if (!dataPopup.current['PeriodName']) {
                        const newError = { ...error };
                        newError['PeriodName'] = Config.lang('Truong_nay_bat_buoc_nhap');
                        setError(newError);
                    } else {
                        const newError = { ...error };
                        delete newError['PeriodName'];
                        setError(newError);
                    }
                })
            );
        } else {
            const newError = { ...error };
            newError['PeriodName'] = Config.lang('Truong_nay_bat_buoc_nhap');
            setError(newError);
        }
    };

    const saveReview = () => {
        const param = {
            UserID: Config.profile.UserID,
            HostID: '',
            FormID: 'W39F3005',
            Status1: status1,
            Status2: status2,
            GoalPlanID: goalPlanID,
            EmployeeID: employeeID || '',
            PeriodName: periodName.toString(),
            DateFrom: moment(dataPopup.current.DateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
            DateTo: moment(dataPopup.current.DateTo, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
        };
        dispatch(
            W39F3005Actions.save(param, async (err, data) => {
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    if (data?.Status === 1) return Config.popup2.show('ERROR', data?.Message);
                    await saveHistory(data);
                    const param = {
                        TransID: _.get(data, 'TransID', ''),
                        UserID: Config.profile.UserID || '',
                    };
                    dispatch(
                        W39F3005Actions.check(param, (err, dt) => {
                            if (err) return Config.popup2.show('ERROR', err);
                            Config.notify.show('success', Config.lang('Tao_review_thanh_cong'), 2000);
                            if (dt?.[0]?.Status) {
                                const param = {
                                    goalProps: {
                                        TransID: data.TransID || '',
                                        IsEdit: 1,
                                    },
                                };
                                browserHistory.push({
                                    pathname: Config.getRootPath() + 'W39F3010',
                                    state: param,
                                });
                            } else {
                                handleOpenW39F3005();
                            }
                        })
                    );
                }
            })
        );
    };

    const saveHistory = async (data) => {
        if (!data) return false;
        const TransID = _.get(data, 'TransID', '');
        if (TransID) {
            const goalPlan = dataCboGoalTable.find((i) => i.GoalPlanID === goalPlanID);
            const employee = Config.getUser({ EmployeeID: employeeID || '' });

            const _data = {
                GoalPlanName: goalPlan ? goalPlan.GoalPlanName : '',
                EmployeeName: employee ? employee.EmployeeName : '',
                PeriodName: periodName.toString() || '',
                DateFrom: moment(dataPopup.current.DateFrom, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                DateTo: moment(dataPopup.current.DateTo, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            };

            const captions = {
                GoalPlanName: 'DHR_Bang_muc_tieu',
                EmployeeName: 'DHR_Nhan_vien',
                PeriodName: 'DHR_Giai_doan',
                DateFrom: 'DHR_Ngay_tu',
                DateTo: 'DHR_Ngay_den',
            };

            const options = {
                data: _data,
                captions: captions,
                action: 0,
                ModuleID: 'D39',
                TransID: TransID,
                TransactionID: 'W39F3005',
                TransactionName: 'Thêm review mục tiêu',
            };

            const history = new History(options);
            await history.save();
        } else {
            Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    const handleOpenCboGoalPlan = (e) => {
        if (!employeeID) {
            const newError = { ...error };
            newError['EmployeeID'] = Config.lang('Truong_nay_bat_buoc_nhap');
            setError(newError);
            if (e) {
                e.component.close();
            }
        }
    };

    const handleCloseCbo = (key) => {
        const newError = { ...error };
        switch (key) {
            case 'GoalPlanID':
                if (!goalPlanID) {
                    newError[key] = Config.lang('Truong_nay_bat_buoc_nhap');
                } else {
                    delete newError[key];
                }
                break;
            case 'EmployeeID':
                if (!employeeID) {
                    newError[key] = Config.lang('Truong_nay_bat_buoc_nhap');
                } else {
                    delete newError[key];
                }
                break;
            default:
                break;
        }
        setError(newError);
    };

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: '#Popup-W39F3005' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <Modal
                id={'Popup-W39F3005'}
                title={Config.lang('Review_muc_tieu')}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={(e) => handleOpenW39F3005()}
            >
                <Modal.Content>
                    <div className={classes.centerRow}>
                        <Typography type={'p2'} style={{ fontWeight: 'bold' }}>
                            {Config.lang('Nhan_vien')}
                            <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Combo.LoadMore
                            dataSource={dataCboEmployees}
                            skip={paramCboEmployee.skip}
                            limit={paramCboEmployee.limit}
                            displayExpr={'EmployeeName'}
                            keyExpr={'EmployeeID'}
                            valueExpr={'EmployeeID'}
                            value={employeeID}
                            loading={loadingCboEmployee}
                            stylingMode={'underlined'}
                            placeholder={Config.lang('Chon')}
                            itemRender={(e) => {
                                const { data } = e;
                                if (!data) return null;
                                return data.EmployeeID + ' - ' + data.EmployeeName;
                            }}
                            showClearButton={true}
                            onClosed={(e) => handleCloseCbo('EmployeeID')}
                            onValueChanged={(e) => handleChange('EmployeeID', e.value)}
                            onInputChanged={(e) => {
                                paramCboEmployee.search = e.target.value;
                                paramCboEmployee.skip = 0;
                                loadCboEmployees(true);
                            }}
                            onLoadMore={(e) => {
                                paramCboEmployee.skip = e.skip;
                                paramCboEmployee.limit = e.limit;
                                loadCboEmployees();
                            }}
                            style={{ width: 'auto' }}
                            error={error && error['EmployeeID']}
                        />
                    </div>
                    <div className={classes.centerRow}>
                        <Typography type={'p2'} style={{ fontWeight: 'bold' }}>
                            {Config.lang('Bang_muc_tieu')}
                            <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Combo
                            dataSource={dataCboGoalTable}
                            displayExpr={'GoalPlanName'}
                            valueExpr={'GoalPlanID'}
                            onOpened={handleOpenCboGoalPlan}
                            onClosed={(e) => handleCloseCbo('GoalPlanID')}
                            onValueChanged={(e) => handleChange('GoalPlanID', e.value)}
                            showClearButton={true}
                            value={goalPlanID}
                            placeholder={Config.lang('Chon')}
                            stylingMode={'underlined'}
                            style={{ width: 'auto' }}
                            error={error && error['GoalPlanID']}
                        />
                    </div>
                    <div className={classes.centerRow}>
                        <Typography type={'p2'} style={{ fontWeight: 'bold' }}>
                            {Config.lang('Trang_thai')}
                            <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <div style={{ position: 'relative' }}>
                            <div>
                                <Checkbox checked={!!status1} label={Config.lang('Chua_hoan_thanh')} onChange={(e) => handleChange('Status1')} />
                                <Checkbox checked={!!status2} label={Config.lang('Hoan_thanh')} onChange={(e) => handleChange('Status2')} />
                            </div>
                            {error && error['Status'] && (
                                <Typography
                                    type={'p2'}
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 400,
                                        color: '#F44336',
                                        position: 'absolute',
                                        bottom: -15,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {error['Status']}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <div className={classes.centerRow}>
                        <Typography type={'p2'} style={{ fontWeight: 'bold' }}>
                            {Config.lang('Giai_doan1')}
                            <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <div className={classes.divPeriodCont}>
                            <div
                                className={classes.divPeriod}
                                ref={divPeriod}
                                onClick={(e) => togglePopPeriod(true)}
                                style={{
                                    color: error && error['PeriodName'] ? '#F44336' : Config.color ? Config.color.MainColor : '#111D5E',
                                    borderColor: error && error['PeriodName'] ? '#F44336' : Config.color ? Config.color.MainColor : '#111D5E',
                                }}
                            >
                                {!periodName && <CalendarNew width={24} height={24} viewBox color={'currentColor'} />}
                                <Typography type={'h4'} color={'currentColor'}>
                                    {periodName || Config.lang('Chon_giai_doan')}
                                </Typography>
                            </div>
                            {error && error['PeriodName'] && (
                                <Typography
                                    type={'p2'}
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 400,
                                        color: '#F44336',
                                        position: 'absolute',
                                        bottom: -20,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {error['PeriodName']}
                                </Typography>
                            )}
                        </div>
                        <Popover
                            open={isOpenPopPeriod}
                            onClose={() => togglePopPeriod()}
                            anchor={divPeriod}
                            width={divPeriod.current ? divPeriod.current.clientWidth : 'fit-content'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 8,
                            }}
                        >
                            {periodArr.map((i) => {
                                return (
                                    <div key={i.PeriodID} className={classes.typoPeriod} onClick={(e) => handleChange('PeriodID', i.PeriodID)}>
                                        <Typography type={'p2'}>{i.PeriodName}</Typography>
                                    </div>
                                );
                            })}
                        </Popover>
                    </div>
                    <Button
                        disabled={!employeeID || !goalPlanID || !periodName || (!status1 && !status2)}
                        color={'info'}
                        viewType={'filled'}
                        text={Config.lang('Tao_review')}
                        style={{ float: 'right' }}
                        onClick={() => saveReview()}
                    />
                </Modal.Content>
            </Modal>
            {periodID !== null && (
                <W39F3005DateModal
                    mode={periodID === 1 ? 'quarter' : periodID === 2 ? 'year' : 'month'}
                    open={periodID !== null}
                    classes={classes}
                    dateFrom={dataPopup.current.DateFrom}
                    dateTo={dataPopup.current.DateTo}
                    onClose={(e) => handleChange('PeriodID', null)}
                    onSelect={handleSelectDate}
                />
            )}
        </>
    );
}

export default W39F3005;
