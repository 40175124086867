/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 19/4/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Column, PatternRule, RequiredRule } from 'devextreme-react/data-grid';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import GridContainer from '../../../../grid-container/grid-container';
import History from '../../../../libs/history';

W39F3002UpdateProportion.propTypes = {
    open: PropTypes.bool,
    classes: PropTypes.object,
    GoalObjectID: PropTypes.string,
    GoalObjectName: PropTypes.string,
    handleOpenPopup: PropTypes.func,
};

W39F3002UpdateProportion.defaultProps = {
    open: false,
    classes: {},
    GoalObjectID: '',
    GoalObjectName: '',
    handleOpenPopup: null,
};

function W39F3002UpdateProportion(props) {
    const { open, classes, GoalObjectID, GoalObjectName, handleOpenPopup } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const originData = useRef([]);
    const isUpdate = useRef(false);
    const dataGridRef = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            GoalObjectID,
        };
        dispatch(
            W39F3002Actions.getPopupData(param, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt) {
                    originData.current = JSON.parse(JSON.stringify(dt));
                    setData(dt);
                }
            })
        );
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([loadData()]).then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateProportion = (dtP, index) => {
        if (dtP?.Proportion !== originData.current?.[index]?.Proportion) {
            isUpdate.current = true;
            const param = {
                GoalID: dtP?.GoalID ?? '',
                Proportion: dtP?.Proportion ?? 0,
            };
            dispatch(
                W39F3002Actions.updateProportion(param, (err, dt) => {
                    if (err) {
                        const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                        return Config.popup2.show('ERROR', msg);
                    }
                    if (dt && dt.Status) {
                        return Config.notify.show('error', Config.lang('Cap_nhat_trong_so_ket_qua_then_chot_that_bai'), 2000);
                    }
                })
            );
        }
    };

    const saveHistory = async (dtP, index) => {
        if (!dtP) return false;
        if (dtP?.GoalID) {
            const _data = {
                GoalObjectName: `${dtP?.GoalID ?? ''}-${dtP?.GoalName ?? ''}`,
                Proportion: dtP?.Proportion ?? 0,
            };
    
            const _dataOld = {
                GoalObjectName: `${dtP?.GoalID ?? ''}-${dtP?.GoalName ?? ''}`,
                Proportion: originData.current?.[index]?.Proportion ?? 0,
            };
    
            const captions = {
                GoalObjectName: 'Ten_muc_tieu',
                Proportion: 'Trong_so',
            };
    
            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: 'D39',
                TransID: dtP?.GoalID ?? '',
                TransactionID: 'W39F3002',
                TransactionName: 'Thông tin mục tiêu',
            };
    
            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        }
        else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
        
    };

    const handleUpdate = async () => {
        setLoading(true);
        dataGridRef.current && dataGridRef.current.instance.saveEditData();
        await Promise.all([
            data.forEach((i, index) => {
                updateProportion(i, index);
                saveHistory(i, index);
            }),
        ]);
        setLoading(false);
        handleOpenPopup('updateProportion', false, isUpdate.current);
        isUpdate.current && Config.notify.show('success', Config.lang('Cap_nhat_trong_so_ket_qua_then_chot_thanh_cong'), 2000);
    };

    return (
        <>
            <LoadPanel
                shadingColor={'rgba(0,0,0,0.4)'}
                position={{ my: 'center', of: '#root' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <Modal width={'50%'} style={{ minWidth: 250 }} open={open} onClose={(e) => handleOpenPopup('updateProportion', false)}>
                <ModalHeader>{GoalObjectName ?? ''}</ModalHeader>
                <ModalBody>
                    <GridContainer
                        reference={(ref) => dataGridRef.current = ref}
                        style={{ border: 'none' }}
                        dataSource={data}
                        showRowLines={true}
                        showBorders={false}
                        showColumnLines={false}
                        noDataText={Config.lang('TM_No_data')}
                        rowAlternationEnabled={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        repaintChangesOnly={true}
                        editing={{
                            mode: 'cell',
                            refreshMode: 'reshape',
                            allowUpdating: true,
                        }}
                        elementAttr={{
                            style: 'max-height: 230px',
                            class: classes.grid
                        }}
                        onEditorPreparing={(e) => {
                            e.editorOptions.readOnly = e.row.data.Status;
                        }}
                    >
                        <Column
                            caption={Config.lang('Ten_ket_qua_then_chot')}
                            alignment={'left'}
                            dataField={'GoalName'}
                            allowSorting={false}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang('Trong_so')}
                            alignment={'right'}
                            dataField={'Proportion'}
                            dataType={'number'}
                            allowSorting={false}
                        >
                            <RequiredRule message={Config.lang('Truong_nay_bat_buoc_nhap')} />
                            <PatternRule message={Config.lang('Phai_la_so_lon_hon_hoac_bang_khong')} pattern={/^\d+((,|.)\d+)?$/} />
                        </Column>
                    </GridContainer>
                </ModalBody>
                <ModalFooter>
                    <Button
                        viewType={'filled'}
                        color={'info'}
                        startIcon={'Refresh'}
                        onClick={() => handleUpdate()}
                        text={Config.lang('Cap_nhat')}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
}

export default W39F3002UpdateProportion;
