/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/4/2021
 * @Example
 */

import { Button, DatePicker, Modal, ModalBody, ModalFooter, ModalHeader, MoneyInput, TextInput } from 'diginet-core-ui/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import { MForm } from '../../../../common/form-material';
import History from '../../../../libs/history';
import { replaceAndParseToNumber } from './commons';

W39F3002AddTR.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    goalProps: PropTypes.object,
};

W39F3002AddTR.defaultProps = {
    classes: {},
    open: false,
    handleOpenPopup: null,
    goalProps: {},
};

function W39F3002AddTR(props) {
    const { classes, open, handleOpenPopup, goalProps } = props;

    const [data, setData] = useState({});
    const [error, setError] = useState({});

    const typingRef = useRef(null);

    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        switch (key) {
            case 'GoalName':
            case 'Proportion':
                if (!!!value || value.length === 0) {
                    setError({ ...error, [key]: Config.lang('Truong_nay_bat_buoc_nhap') });
                } else {
                    setError({ ...error, [key]: '' });
                }
                setData({ ...data, [key]: value });
                break;
            case 'ValidDateFrom':
            case 'ValidDateTo':
                if (!value || value.length === 0) {
                    setError({ ...error, [key]: Config.lang('Truong_nay_bat_buoc_nhap') });
                } else {
                    setError({ ...error, [key]: '' });
                }
                setData({ ...data, [key]: value });
                break;
            case 'Description':
                if (typingRef.current) {
                    clearInterval(typingRef.current);
                }

                typingRef.current = setTimeout(() => {
                    setData({ ...data, [key]: value });
                }, 500);
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        const validateRules = [
            {
                name: 'GoalName',
                rules: ['isRequired'],
                value: data?.GoalName ?? '',
            },
            {
                name: 'ValidDateFrom',
                rules: ['isRequired'],
                value: data?.ValidDateFrom ?? '',
            },
            {
                name: 'ValidDateTo',
                rules: ['isRequired'],
                value: data?.ValidDateTo ?? '',
            },
            {
                name: 'Proportion',
                rules: ['isRequired'],
                value: data?.Proportion ?? '',
            },
        ];

        const validateForm = MForm.formValidation(validateRules);

        if (Object.keys(validateForm).length > 0) {
            setError(validateForm);
            return;
        }

        const param = {
            ...data,
            Proportion: replaceAndParseToNumber(data?.Proportion ?? 0),
            ...goalProps,
        };

        dispatch(
            W39F3002Actions.addResult(param, async (err, dt) => {
                if (err) {
                    const msg = err?.massage ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt?.Status === 0) {
                    const goalID = dt?.GoalID ?? '';
                    await saveHistory(goalID);
                    handleOpenPopup('addTR', false, { GoalID: dt?.GoalID ?? '' });
                    Config.notify.show('success', Config.lang('Them_ket_qua_then_chot_thanh_cong'), 2000);
                } else if (dt?.Status === 1) {
                    Config.notify.show('error', Config.lang('Them_ket_qua_then_chot_that_bai'), 2000);
                }
            })
        );
    };

    const saveHistory = async (goalID) => {
        if (!data) return false;
        if (goalID) {
            const _data = {
                GoalName: data?.GoalName ?? '',
                ValidDateFrom: data?.ValidDateFrom ?? '',
                ValidDateTo: data?.ValidDateTo ?? '',
                Proportion: data?.Proportion ?? '',
                Description: data?.Description ?? '',
            };
    
            const captions = {
                GoalName: 'Ten',
                ValidDateFrom: 'Ngay_bat_dau',
                ValidDateTo: 'Ngay_hoan_thanh',
                Proportion: 'Trong_so',
                Description: 'Mo_ta',
            };
    
            const options = {
                data: _data,
                captions: captions,
                action: 0,
                ModuleID: 'D39',
                TransID: goalID ?? '',
                TransactionID: 'W39F3002',
                TransactionName: 'Thông tin mục tiêu',
            };
    
            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        }
        else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    return (
        <Modal width={'50%'} style={{ minWidth: 250 }} open={open} onClose={(e) => handleOpenPopup('addTR', false, false)}>
            <ModalHeader>{Config.lang('Them_ket_qua_then_chot')}</ModalHeader>
            <ModalBody>
                <TextInput
                    onChange={(e) => handleChange('GoalName', e.target.value)}
                    value={data?.GoalName ?? ''}
                    label={Config.lang('Ten')}
                    required
                    error={error && error['GoalName']}
                />
                <div className={`${classes.divDatePicker}`}>
                    <DatePicker
                        label={Config.lang('Ngay_bat_dau')}
                        required
                        clearAble
                        actionIconAt={'start'}
                        max={data?.ValidDateTo ?? moment().format('YYYY/MM/DD')}
                        placeholder={'DD/MM/YYYY'}
                        displayFormat={'DD/MM/YYYY'}
                        value={data?.ValidDateFrom ?? ''}
                        viewType={'underlined'}
                        onChange={(e) => handleChange('ValidDateFrom', e.value)}
                        error={error && error['ValidDateFrom']}
                    />
                    <DatePicker
                        label={Config.lang('Ngay_hoan_thanh')}
                        required
                        clearAble
                        actionIconAt={'start'}
                        min={data?.ValidDateFrom}
                        placeholder={'DD/MM/YYYY'}
                        displayFormat={'DD/MM/YYYY'}
                        value={data?.ValidDateTo ?? ''}
                        viewType={'underlined'}
                        onChange={(e) => handleChange('ValidDateTo', e.value)}
                        error={error && error['ValidDateTo']}
                    />
                </div>
                <MoneyInput
                    onChange={(e) => handleChange('Proportion', e.target.value)}
                    value={data?.Proportion ?? ''}
                    label={Config.lang('Trong_so')}
                    allowZero
                    disabledNegative
                    required
                    error={error && error['Proportion']}
                />
                <TextInput
                    onChange={(e) => handleChange('Description', e.target.value)}
                    value={data?.Description ?? ''}
                    label={Config.lang('Mo_ta')}
                    multiline
                    style={{ marginBottom: 10 }}
                    error={error && error['Description']}
                />
            </ModalBody>
            <ModalFooter>
                <Button viewType={'filled'} color={'info'} startIcon={'Save'} onClick={() => handleSave()} text={Config.lang('Luu')} />
            </ModalFooter>
        </Modal>
    );
}

export default W39F3002AddTR;
