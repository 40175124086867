/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 16/4/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Column, PatternRule, RequiredRule } from 'devextreme-react/data-grid';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import GridContainer from '../../../../grid-container/grid-container';

W39F3002ReorderItem.propTypes = {
    open: PropTypes.bool,
    classes: PropTypes.object,
    GoalObjectID: PropTypes.string,
    GoalObjectName: PropTypes.string,
    handleOpenPopup: PropTypes.func,
};

W39F3002ReorderItem.defaultProps = {
    open: false,
    classes: {},
    GoalObjectID: '',
    GoalObjectName: '',
    handleOpenPopup: null,
};

function W39F3002ReorderItem(props) {
    const { open, classes, GoalObjectID, GoalObjectName, handleOpenPopup } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const dataGridRef = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            GoalObjectID,
        };
        dispatch(
            W39F3002Actions.getPopupData(param, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt) {
                    setData(dt);
                }
            })
        );
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([loadData()]).then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDisplayOrder = async (data) => {
        const param = {
            GoalID: data?.GoalID ?? '',
            DisplayOrder: data?.DisplayOrder ?? 0,
        };
        dispatch(
            W39F3002Actions.updateDisplayOrder(param, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt && dt.Status) {
                    return Config.notify.show('error', Config.lang('Cap_nhat_thu_tu_ket_qua_then_chot_that_bai'), 2000);
                }
            })
        );
    };

    const handleUpdate = () => {
        setLoading(true);
        dataGridRef.current && dataGridRef.current.instance.saveEditData();
        Promise.all(data.map((i) => updateDisplayOrder(i))).then(() => {
            setTimeout(() => {
                setLoading(false);
                handleOpenPopup('reorderPop', false);
                Config.notify.show('success', Config.lang('Cap_nhat_thu_tu_ket_qua_then_chot_thanh_cong'), 2000);
            }, 300);
        });
    };

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: '#root' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <Modal width={'50%'} style={{ minWidth: 250 }} open={open} onClose={(e) => handleOpenPopup('reorderPop', false)}>
                <ModalHeader>{GoalObjectName ?? ''}</ModalHeader>
                <ModalBody>
                    <GridContainer
                        reference={(ref) => dataGridRef.current = ref}
                        style={{ border: 'none' }}
                        dataSource={data}
                        showRowLines={true}
                        showBorders={false}
                        showColumnLines={false}
                        noDataText={Config.lang('TM_No_data')}
                        rowAlternationEnabled={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        repaintChangesOnly={true}
                        editing={{
                            mode: 'cell',
                            refreshMode: 'reshape',
                            allowUpdating: true,
                        }}
                        elementAttr={{
                            style: 'max-height: 230px',
                            class: classes.grid
                        }}
                    >
                        <Column
                            caption={Config.lang('Ten_ket_qua_then_chot')}
                            alignment={'left'}
                            dataField={'GoalName'}
                            allowSorting={false}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang('Thu_tu')}
                            alignment={'right'}
                            dataField={'DisplayOrder'}
                            dataType={'number'}
                            allowSorting={false}
                        >
                            <RequiredRule message={Config.lang('Truong_nay_bat_buoc_nhap')} />
                            <PatternRule message={Config.lang('Phai_la_so_nguyen_duong')} pattern={/^[0-9]*$/} />
                        </Column>
                    </GridContainer>
                </ModalBody>
                <ModalFooter>
                    <Button viewType={'filled'} color={'info'} startIcon={'Refresh'} onClick={() => handleUpdate()} text={Config.lang('Cap_nhat')} />
                </ModalFooter>
            </Modal>
        </>
    );
}

export default W39F3002ReorderItem;
