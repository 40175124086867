/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 13/4/2021
 * @Example
 */

import { AttachChip, LinearProgress, Typography } from 'diginet-core-ui/components';
import Icon, { Archive } from 'diginet-core-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import CDN from '../../../../CDN';

W39F3002Tab1DetailItem.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleOpenPopup: PropTypes.func
};

W39F3002Tab1DetailItem.defaultProps = {
    classes: {},
    data: {},
    handleOpenPopup: null
};

function W39F3002Tab1DetailItem(props) {
    const { classes, data, handleOpenPopup } = props;

    const [totalComments, setTotalComments] = useState(0);
    const [attachments, setAttachments] = useState([]);
    const [error, setError] = useState({});

    const inputRef = useRef(null);

    const dispatch = useDispatch();

    const loadAttachments = () => {
        const param = {
            KeyID: data?.GoalKeyName?.[0] ?? '',
            TableName: 'D39T2045'
        };

        dispatch(W39F3002Actions.getAttachments(param, (err, dt) => {
            if (err) return Config.popup2.show('ERROR', err);
            if (dt) {
                setAttachments(dt);
            }
        }));
    };

    const loadTotalComments = () => {
        const param = {
            GoalID: data?.GoalKeyName?.[0] ?? '',
            FormID: 'W39F3002'
        }

        dispatch(W39F3002Actions.getTotalComments(param, (err, dt) => {
            if (err) return Config.popup2.show('ERROR', err);
            if (dt) {
                setTotalComments(dt);
            }
        }));
    }

    useEffect(() => {
        loadAttachments();
        loadTotalComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleChangeFile = async () => {
        const files = inputRef.current.files;
        if (files.length > 0) {
            let listAttachment = [];
            const numberCurrentAttachs = attachments.length;
            const numberInputFile = files.length;

            if (numberCurrentAttachs + numberInputFile > 5) {
                setError({ ...error, 'attachment': Config.lang('File_vuot_qua_so_luong_cho_phep') });
                return;
            }

            Object.keys(files).forEach(i => {
                if (files[i].size / 2048 > 25) {
                    setError({ ...error, 'attachment': Config.lang('File_vuot_qua_dung_luong_cho_phep') });
                    return;
                }
            });

            setError({ ...error, 'attachment': '' });

            await Promise.all(Object.keys(files).map(async i => {
                return await uploadFile([files[i]]);
            })).then(attachs => {
                listAttachment = attachs.map(i => {
                    const splitName = i?.fileName?.split('.');
                    return {
                        URL: i?.filePath ?? '',
                        FileName: i?.fileName ?? '',
                        FileSize: i?.fileSize ?? 0,
                        FileExt: splitName?.[splitName.length - 1] ?? '',
                        Description: '',
                        DescriptionU: '',
                    }
                });
            });

            const param = {
                VoucherID: data?.GoalKeyName?.[0] ?? '',
                TableName: 'D39T2045',
                DivisionID: Config.getDivisionID(),
                arrAttachment: JSON.stringify(listAttachment),
            };

            dispatch(W39F3002Actions.addAttachments(param, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
                if (dt?.length === listAttachment.length) {
                    dt.forEach((i, idx) => {
                        listAttachment[idx].AttachmentID = i.AttachmentID ?? '';
                        listAttachment[idx].CreateUserID = Config?.profile?.UserID ?? '';
                        listAttachment[idx].UserName = Config?.profile?.UserNameU ?? '';
                        listAttachment[idx].CreateDate = new Date().toISOString() ?? '';
                    });
                    setAttachments([...attachments, ...listAttachment]);
                    return Config.notify.show('success', Config.lang('Them_dinh_kem_thanh_cong'), 2000);
                }
                else {
                    return Config.notify.show('error', Config.lang('Them_dinh_kem_that_bai'), 2000);
                }
            }));
        }
    };

    const uploadFile = async file => {
        return await new Promise((rs, rj) => {
            CDN.uploadFile(file, null, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    Config.popup2.show('ERROR', msg);
                    rj(err);
                }
                rs(dt?.paths?.[0]);
            });
        });
    };

    const removeFile = (file) => {
        const param = {
            VoucherID: data?.GoalKeyName?.[0] ?? '',
            TableName: 'D39T2045',
            AttachmentID: file?.AttachmentID ?? ''
        };

        dispatch(W39F3002Actions.removeAttachment(param, (err, dt) => {
            if (err) {
                const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                return Config.popup2.show('ERROR', msg);
            }
            if (dt === 'Deleted') {
                const newAttachments = [...attachments].filter(i => i.AttachmentID !== file.AttachmentID);
                setAttachments(newAttachments);
                return Config.notify.show('success', Config.lang('Xoa_dinh_kem_thanh_cong'), 2000);
            }
            else {
                return Config.notify.show('error', Config.lang('Xoa_dinh_kem_that_bai'), 2000);
            }
        }));
    };

    const handleChooseFile = () => {
        if (!data?.IsAllEdit) return;
        if (inputRef.current) inputRef.current.click();
    };

    return (
        <div className={`${classes.tab1Detail} ${classes.bodyDetail}`}>
            <div className={`${classes.flexCenter} ${classes.gap10} ${classes.justifyBetween} ${classes.relativePos}`}>
                <div className={`${classes.flexColumn} ${classes.gap10} ${classes.fullWidth}`}>
                    <Typography type={'h2'} onClick={e => handleOpenPopup('W39F3002', true, { GoalID: data?.GoalKeyName?.[0] ?? '', Type: data?.Type ?? '' })} className={`${classes.pointerCursor}`}>
                        {data?.GoalKeyName?.[1] ?? ''}
                    </Typography>
                    <div className={`${classes.flexCenter} ${classes.gap10} ${classes.fullWidth} ${classes.flexWrap}`}>
                        <div className={`${classes.flexCenter} ${classes.gap5}`}>
                            <InlineSVG src={require('../../W39F3000/icons/comment.svg')} width={24} height={24} className={classes.iconHover} />
                            <Typography type={'p2'} color={'secondary'}>{totalComments ?? 0}</Typography>
                        </div>
                        <div className={`${classes.flexCenter} ${classes.gap5}`}>
                            <Icon id={'Calendar'} name={'Calendar'} viewBox />
                            <Typography type={'p2'} color={'secondary'}>{data?.ValidDateTo ? moment(data?.ValidDateTo).format('DD/MM/YYYY') : ''}</Typography>
                        </div>
                    </div>
                    <div className={`${classes.flexCenter} ${classes.gap5} ${classes.fullWidth} ${classes.flexWrap}`}>
                        <InlineSVG src={require('../icons/user.svg')} width={24} height={24} className={classes.iconHover} />
                        <Typography type={'p2'} color={'secondary'}>{data?.EmployeeNameKey ?? ''}</Typography>
                    </div>
                    {attachments.length > 0 && <div className={`${classes.flexCenter} ${classes.gap10} ${classes.fullWidth} ${classes.flexWrap}`}>
                        {attachments.map((i, idx) =>
                            <AttachChip file={i} key={i?.AttachmentID ?? idx} maxLength={15} readOnly={!data?.IsAllEdit} onRemove={(e, file) => removeFile(file)} />
                        )}
                    </div>}
                    <div className={`${classes.flexColumn} ${classes.gap5} ${classes.flexWrap}`}>
                        <div className={`${classes.flexCenter} ${classes.gap5} ${data?.IsAllEdit && classes.pointerCursor}`} onClick={() => handleChooseFile()}>
                            <Icon id={'Attachment'} name={'Attachment'} viewBox style={{ cursor: data?.IsAllEdit && 'pointer' }} />
                            <Typography type={'p2'} color={'secondary'}>{Config.lang('Them_tep_dinh_kem')}</Typography>
                        </div>
                        <input ref={inputRef} type='file' multiple style={{ display: 'none' }} onChange={() => handleChangeFile()} />
                        {error?.['attachment'] && <span style={{ color: 'red', fontSize: 12 }}>{error?.['attachment']}</span>}
                    </div>
                </div>
                <Icon id='iconEdit' name='Edit' type='filled' onClick={e => handleOpenPopup('W39F3004', true, data)}
                    style={{ visibility: 'hidden', color: '#7F828E', background: 'white', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)', position: 'absolute', right: '7%' }} />
            </div>
            <div className={`${classes.flexColumn} ${classes.alignCenter} ${classes.justifyCenter} ${classes.gap10}`}>
                <LinearProgress
                    color={'#0095FF'}
                    background={'#D4D5D9'}
                    duration={1}
                    determinate
                    width={'100%'}
                    height={8}
                    percent={data?.PercentResult ?? 0}
                    showValue
                    onClick={e => handleOpenPopup('W39F3016', true, {
                        GoalID: data?.GoalKeyName?.[0] ?? '',
                        IsAllEdit: data?.IsAllEdit ?? 0,
                        IsEditMethod: data?.IsEditMethod ?? 0,
                        IsEditResult: data?.IsEditResult ?? 0,
                        Type: 'Key'
                    })}
                    style={{ cursor: data?.IsAllEdit && data?.IsEditResult ? 'pointer' : 'default' }}
                />
                <Typography type={'p2'} style={{ marginTop: 20 }}>{data?.Result ?? ''}</Typography>
            </div>
            <div className={`${classes.flexCenter} ${classes.justifyCenter}`}>
                <Typography type={'h3'} fullWidth={false}>{data?.Proportion ?? 0}%</Typography>
            </div>
            <div className={`${classes.flexCenter} ${classes.justifyCenter}`}>
                <Archive viewBox height={20} width={20} color={data?.StatusID === 1 ? '#0095FF' : '#7F828E'} />
            </div>
        </div >
    );
}

export default W39F3002Tab1DetailItem;